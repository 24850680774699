<template>
  <b-row class="row job-list py-1 w-100">
    <b-col cols="12" lg="4" v-for="(item, index) in jobs" :key="index">
      <b-card>
        <div class="row top-part">
          <div class="col-lg-3">
            <img
              src="@/assets/images/logo/demo.png"
              min-height="64"
              min-width="64"
              alt="avatar"
              class="rounded-circle mr-2 img-fluid"
            />
          </div>
          <div class="col-md-9">
            <h3 maxlength="15" class="title">{{ item.title }}</h3>

            <div class="item">
              <img
                src="@/assets/images/icons/building.png"
                alt=""
                class="mr-1"
              />
              <span class="detail-text">{{ item.user.first_name }}</span>
            </div>
            <div class="item">
              <img
                src="@/assets/images/icons/briefcase.png"
                alt=""
                class="mr-1"
              />
              <span class="detail-text">{{
                item.customer ? item.customer.name : "N/A"
              }}</span>
            </div>
            <div class="item">
              <img
                src="@/assets/images/icons/map-pin.png"
                alt=""
                class="mr-1"
              />
              <span class="detail-text">{{ getLocationsName(item.location) }}</span>
            </div>
            <div class="item">
              <img src="@/assets/images/icons/clock.png" alt="" class="mr-1" />
              <span class="detail-text">{{ item.hours }}</span>
            </div>
          </div>
        </div>
        <hr />
        <div class="buttons d-flex justify-content-between align-items-center">
          <div>
            <router-link
              v-if="$can('view', 'Job Post')"
              :to="`/job-post/preview/${
                item.job_post_id ? item.job_post_id : item.id
              }`"
            >
              <b-button
                v-b-tooltip.hover
                title="View"
                size="sm"
                class="preview rounded-circle"
                v-if="logedInUser.roles[0].name !== 'employee'"
              >
                <img width="15" src="@/assets/images/icons/eye.png" alt="" />
              </b-button>
              <b-button
                v-b-tooltip.hover
                title="View"
                size="sm"
                class="preview btn-sm"
                v-else
              >
                Apply
              </b-button>
            </router-link>

            <router-link
              v-if="$can('update', 'Job Post')"
              :to="`/job-post/edit/${item.id}`"
            >
              <b-button
                v-b-tooltip.hover
                title="Edit"
                size="sm"
                class="preview rounded-circle mx-1"
              >
                <img width="15" src="@/assets/images/icons/edit.png" alt="" />
              </b-button>
            </router-link>
            <b-button
              @click="$bvModal.show(`bv-modal-example-${item.id}`)"
              size="sm"
              variant="danger"
              class="preview btn-danger"
              v-if="$can('delete', 'Job Post') && item.status === 0"
            >
              Delete
            </b-button>
          </div>
          <div>
            <b-button
              v-if="
                item.employee_apply > 0 &&
                logedInUser.roles[0].name == 'employee'
              "
              size="sm"
              class="rounded"
              variant="success"
              >Applied</b-button
            >
            <b-button
              v-if="
                logedInUser.roles[0].name == 'employee' && item.status === 5 && item.hire === 0
              "
              size="sm"
              class="rounded"
              variant="success"
              >Onboarded</b-button
            >
            <b-button
              v-if="
                logedInUser.roles[0].name == 'employee' && item.hire === 4
              "
              size="sm"
              class="rounded"
              variant="danger"
              >Rejected</b-button
            >
            <router-link
              v-if="$can('view applicants', 'Job Post') && item.status !== 0"
              :to="`/job-posts/applicants/${item.id}`"
            >
              <b-button
                :disabled="item.apply_count == 0"
                size="sm"
                class="rounded p-1 ml-1"
                >Applicants ({{
                  item.apply_count ? item.apply_count : 0
                }})</b-button
              >
            </router-link>

            <button
              @click.prevent="publishJob(item.id)"
              class="btn btn-sm publish-btn rounded shadow-sm px-2"
              v-if="$can('publish', 'Job Post') && item.status === 0"
            >
              <span class="text-white">Publish</span>
            </button>
          </div>
        </div>
      </b-card>
      <b-modal size="sm" :id="`bv-modal-example-${item.id}`" hide-footer>
        <template #modal-title>Delet Job Post! </template>
        <div class="d-block text-center">
          <h4 class="text-danger">
            Are you confirmed to delete the job draft ?
          </h4>
        </div>
        <div class="d-flex justify-content-between text-center">
          <button
            class="mt-3 btn btn-danger"
            @click="
              $bvModal.hide(`bv-modal-example-${item.id}`);
              deleteJob(item.id);
            "
          >
            Delete
          </button>
          <button
            class="mt-3 btn btn-success"
            @click="$bvModal.hide('`bv-modal-example-${item.id}`')"
          >
            Cancel
          </button>
        </div>
      </b-modal>
    </b-col>
  </b-row>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInput,
  BRow,
  VBTooltip,
  BModal,
} from "bootstrap-vue";
import { getLogedInUser } from "@/utils/auth";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BRow,
    BCol,
    BCard,
    BBadge,
    BAvatar,
    BButton,
    BDropdown,
    BDropdownItem,
    BFormInput,
    BFormGroup,
    BModal,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  props: {
    jobs: {
      type: Array,
      default: () => [],
    },
    locations: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      clients: [],
      logedInUser: JSON.parse(getLogedInUser()),
    };
  },

  methods: {
    getLocationsName(id) {
      if (this.locations.length > 0) {
        const location_id = parseInt(id);
        let location = "";
        if (!isNaN(location_id) && location_id > 0) {
          location = this.locations.find(
            (location) => location.id === location_id
          );
        }
        return location ? location.name : id;
      }
    },
    publishJob(id) {
      this.$store.dispatch("job/publishJob", { id: id, status: 1 }).then(() => {
        this.$emit("getJobs");
      });
    },
    deleteJob(id) {
      this.$store.dispatch("job/deleteJob", id).then((res) => {
        this.$emit("getJobs");
      });
    },
  },
};
</script>

<style scoped>
.detail-text {
  /* display: none; */
  /* font-family: "Segoe UI" !important; */
  font-style: normal !important;
  font-weight: 600;
  font-size: 13px !important;

  color: #79869f;
}

.btm-text {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: justify;

  /* Text 2 */

  color: #a8adaf;
}

.btn-secondary {
  border-color: white !important;

  /* Gradient */

  background: linear-gradient(#01185e 0%, #264296 44.92%, #7190ef);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  background-color: white;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.publish-btn {
  background: linear-gradient(315deg, #01185e 0%, #264296 44.92%, #7190ef 100%);
  border-radius: 5px;
  color: white;
  border-color: white !important;
}

.label-font {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
}

button.btn.preview.rounded-circle.btn-secondary.btn-sm {
  padding: 10px;
}

.btn-bg {
  border-color: white !important;
}

.item {
  padding-bottom: 5px;
}

.item span {
  font-weight: 600;
  font-size: 13px;
  line-height: 19px;
  color: #79869f;
}

.item img {
  width: 12px;
  height: 12px;
}

.title {
  font-weight: 600;
  font-size: 15px;
  line-height: 27px;
  /* white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; */

  color: #7190ef;
}

@media only screen and (min-width: 1440px) {
  .card-size {
    display: block;
    /* width: 380px; */
    height: 300px;
  }

  .top-part {
    height: 180px;
  }
}

@media only screen and (max-width: 1440px) and (min-width: 1050px) {
  .title {
    font-weight: 600 !important;
    font-size: 15px !important;

    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;

    color: #7190ef;
  }

  .card-size {
    display: block !important;
    width: 245px;
    height: 260px !important;
    padding: 10px !important;
  }

  .top-part {
    height: 160px !important;
  }
}

@media only screen and (max-width: 1024px) and (min-width: 800px) {
  .card-size {
    display: block;
    /* width: 245px; */
    height: 310px;
  }

  .title {
    font-weight: 600;
    font-size: 15px;
    line-height: 27px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    color: #7190ef;
  }

  .top-part {
    height: 160px;
  }
}

@media only screen and (max-width: 768px) and (min-width: 426px) {
  #employee-list .v-select.vs--single.vs--searchable {
    width: 100px;
  }
}

@media only screen and (max-width: 425px) and (min-width: 320px) {
  #employee-list .v-select.vs--single.vs--searchable {
    width: 100%;
  }
}
</style>
